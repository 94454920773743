import { ArrowLeftOutlined, FormOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Col, Row, Select, Spin, Table, message } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from '../../Services/HTTPCalls';
import EditUser from './EditUser';
import { useNavigate } from "react-router";

export const pageSizeOption: number[] = [10,15,30, 50, 100, 200];

type Props = {}
enum ApplicationUserStatus {
    Active = 0,
    Inactive = 1,
    Deleted = 2,
}
interface IUserTable {
    name: string,
    role: string,
    email: string,
    userName: string,
    status: ApplicationUserStatus
}

function UserList(props: Props) {
    const [editId, setEditId] = useState<string>("");
    const [editDetails, setEditDetails] = useState<any>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTable, setIsTable] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<IUserTable[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();

    const [listParams, setListParams] = useState({
        nameSearch: "",
        batchSearch: "",
        sortCol: "Name",
        sortDir: "ascend",
        start: 0,
        length: pageSizeOption[0]
    });
    const changeListParams = (key: string, value: any) => {
        // setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
        setListParams((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    const tableLoading = {
        spinning: isTable,
        indicator: <Spin indicator={<LoadingOutlined />} />,
    };
    const columns: ColumnsType<any> = [
        {
            title: "S. No",
            dataIndex: "sno",
            key: "sno",
            width: "4%",

            render: (text) => <p>{text}</p>,
        },
        {
            title: "Name",
            width: "14%",
            dataIndex: "name",
            ellipsis: true,

            render: (_, record) => (
                <span
                    onClick={() => {
                        setEditId(record?.id);
                        setEditDetails(record);
                        setIsOpen(true);
                    }}
                    style={{ cursor: "pointer", color: "#4096FF" }}
                >
                    {record?.name}
                </span>
            ),
        },
        {
            title: "Role",
            width: "11%",
            ellipsis: true,
            dataIndex: "role",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "Mail",
            width: "11%",
            ellipsis: true,
            dataIndex: "email",
            render: (text) => <span >{text}</span>,
        },
        {
            title: "Status",
            width: "11%",
            ellipsis: true,
            dataIndex: "status",
            render: (status, record) =>
                status === ApplicationUserStatus.Active ? (
                    <Badge status="success" text="Active" className="ps-2" />
                ) : (
                    <Badge status="error" text="Inactive" className="ps-2" />
                ),
        },
        {
            title: " ",
            width: "3%",
            dataIndex: "edit",
            render: (_, record) => (
                <FormOutlined
                    className="ca-edit-btn"
                    style={{ color: "#0d6efd" }}
                    onClick={() => {
                        setEditId(record?.id);
                        setEditDetails(record)
                        setIsOpen(true);
                    }}
                />
            ),
        },
    ];
    useEffect(() => {
        getList()
    }, [shouldRefresh,listParams])
    const getList = async () => {
        setIsTable(true)
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?", listParams)
        if (res?.result?.items?.length > 0) {
            setDataSource(res?.result?.items?.map((item: any) => ({
                id: item?.id,
                key: item?.sno,
                sno: item?.sno,
                name: item?.name?.first,
                role: item?.role,
                email: item?.email,
                status: item?.status
            })))
            setTotalRecords(res?.result?.totalRecords);
            setIsTable(false)
        }
        else {
            setIsTable(false)
            messageApi.error(res?.message)
        }
    }
    return (
        <>
            <Row justify={'space-between'} align="middle" style={{ marginBottom: "6px" }}>
      <ArrowLeftOutlined  style={{color:'#1677ff'}}  onClick={() => {
        navigate('/settings')
      }}/>
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Fees Type",
            },
          ]}
        ></Breadcrumb>
      </Row>

            <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
                <Col span={24}>
                    <Row >
                        <Col lg={21}>
                            <Button
                                type="primary"
                                className="cit-add-btn"
                                onClick={() => {
                                    setEditId("");
                                    
                                    setIsOpen(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                User
                            </Button>
                        </Col>
                        <Col lg={3}>
                            <Search
                                size="middle"
                                placeholder="Search Name..."
                                allowClear
                                className="att-search-input mb-1"
                                onSearch={(val: string) => changeListParams("nameSearch", val)}
                                // onChange={(e: any) =>
                                //   e.target.value === "" ? setListParamsAndRefresh("") : null
                                // }
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                    <Col span={24} style={{ paddingTop: "12px" }}>
                        <Table
                            size="small"
                            className="cit-table"
                            dataSource={dataSource}
                            loading={tableLoading}
                            columns={columns}
                            scroll={{ x: 1100, y: "65vh" }}
                            pagination={{
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`,
                                current: listParams.start / listParams.length + 1,
                                pageSize: listParams.length,
                                showSizeChanger: true,
                                total: totalRecords,
                                onChange: (page, pageSize) => {
                                    changeListParams("start", (page - 1) * pageSize);
                                    changeListParams("length", pageSize);
                                    setShouldRefresh((x) => !x);
                                },
                                pageSizeOptions: pageSizeOption,
                                position: ["bottomRight"]
                            }}
                        // pagination={{
                        //     showTotal: (total, range) =>
                        //         `${range[0]}-${range[1]} of ${total} items`,
                        //     selectPrefixCls: "custom-table-select",
                        //     current: pagingItems.currentPage,
                        //     pageSize: pagingItems.pageSize,
                        //     showSizeChanger: true,
                        //     total: pagingItems.totalRecords,
                        //     pageSizeOptions: ["15", "25", "50", "100"],
                        // }}
                        // onChange={(paging, filter, sort: any) => {
                        //     sort?.order &&
                        //         setListParams({
                        //             ...listParams,
                        //             sortDir: sort?.order,
                        //             sortCol: sort?.columnKey,
                        //         });
                        //     paging &&
                        //         setPagingItems({
                        //             ...pagingItems,
                        //             currentPage: paging?.current ?? 1,
                        //             pageSize: paging?.pageSize ?? 15,
                        //         });
                        // }}
                        //
                        />
                    </Col>
                </Col>
            </Row>
            {isOpen && (
                <div>
                    <EditUser
                        id={editId}
                        editDetails={editDetails}
                        detail={editDetails}
                        open={isOpen}
                        onDismiss={(rec: boolean) => {
                            setIsOpen(false)
                            if (rec) setShouldRefresh((x) => !x);
                        }}
                    />
                </div>
            )}

        </>
    );
}

export default UserList