import { Card, Col, Row, Typography } from "antd";
import React from "react";
import paidIcon from "../../../src/images/currency1.svg";
import LeftIcon from "../../../src/images/currency2.svg";
import DueIcon from "../../../src/images/currency3.svg";
import EChartsReact from "echarts-for-react";
import { Utils } from "../../Utilities/Utils";

const option = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    bottom: "0%",
    left: "0%",
    top: "0%",
    orient: "vertical",
  },

  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: true,
      },
      data: [
        // { value: 1048, name: 'Search Engine' },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Union Ads" },
        // { value: 300, name: 'Video Ads' }
      ],
    },
  ],
};

const FeesGraph = (props: any) => {
  const { FeesGraphData } = props;
  return (
    <Row gutter={18}>
      <Col span={6}>
        <Card className="payAmtCard" style={{ borderRadius: 8 }}>
          <Row>
            <Col span={4}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={paidIcon} alt="Paid" />
              </div>
            </Col>
            <Col span={20}>
              <Typography.Title
                level={4}
                style={{ fontWeight: 400, textAlign: "center" }}
              >
                &#8377;{" "}
                {Utils.getFormattedNumber(FeesGraphData?.totalPaidAmount)}
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{
                  fontWeight: 400,
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                Total Paid
              </Typography.Title>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={6}>
        <Card className="payAmtCard" style={{ borderRadius: 8 }}>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={DueIcon} alt="Due" />
              </div>
            </Col>
            <Col span={20}>
              <Typography.Title
                level={4}
                style={{ fontWeight: 400, textAlign: "center" }}
              >
                &#8377;{" "}
                {Utils.getFormattedNumber(FeesGraphData?.totalRemainingAmount)}
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Total Due
              </Typography.Title>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={6}>
        <Card className="payAmtCard" style={{ borderRadius: 8 }}>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={LeftIcon} alt="Left" />
              </div>
            </Col>
            <Col span={20}>
              <Typography.Title
                level={4}
                style={{ fontWeight: 400, textAlign: "center" }}
              >
                &#8377;{" "}
                {Utils.getFormattedNumber(FeesGraphData?.totalRefundAmount)}
              </Typography.Title>

              <Typography.Title
                level={5}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Total Refund
              </Typography.Title>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={6}>
        <Card className="payAmtCard" style={{ borderRadius: 8 }}>
          <Row>
            <Col span={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={LeftIcon} alt="Left" />
              </div>
            </Col>
            <Col span={20}>
              <Typography.Title
                level={4}
                style={{ fontWeight: 400, textAlign: "center" }}
              >
                &#8377;{" "}
                {Utils.getFormattedNumber(FeesGraphData?.totalFeesAmount)}
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Total Fees
              </Typography.Title>
            </Col>
          </Row>
        </Card>
        {/* <Card className="graphCard" style={{ borderRadius: 8 }}>
          <Row>
            <Col span={18} style={{ textAlign: "center" }}>
              <EChartsReact option={option} style={{ height: "90px" }} />
            </Col>
            <Col span={6}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Title
                  level={5}
                  style={{ fontWeight: 400, fontSize: 15, marginLeft: "-85px" }}
                >
                  <Typography.Title level={4} style={{ fontWeight: 400 }}>
                    &#8377; 0000
                  </Typography.Title>
                  Total Fees
                </Typography.Title>
              </div>
            </Col>
          </Row>
        </Card> */}
      </Col>
    </Row>
  );
};

export default FeesGraph;
