import { CloseOutlined } from "@ant-design/icons";
import {
  Drawer,
  Button,
  Space,
  Form,
  Select,
  DatePicker,
  InputNumber,
  message,
  Input,
} from "antd";
import form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import {
  EnquiryEndPoint,
  FeesTypeMaster,
  PaymentMethodMaster,
} from "../../ApiUrls/URLS";
import { CandidateRequestType, MasterTypes } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
const masterApi = new ApiCalls(
  PaymentMethodMaster.endPoints,
  PaymentMethodMaster.prefix
);
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");
const AddEditFees = (props: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props?.propsData);
  }, [props?.propsData]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [streamData, setStremData] = useState<any[]>([]);
  const [isRefund,setIsRefund]= useState<boolean>(false)
  const [masterData, setMasterData] = useState<{
    batch: any;
  }>({
    batch: [],
  });
  const [masterOptions, setMasterOptions] = useState<any>({
    studentDetails: [{ label: "", value: "" }],
    feesTypeOption: [{ label: "", value: "" }],
    paymentDetails: [{ label: "", value: "" }],
  });
  const GetPaymentMethods = async () => {
    let res = await masterApi.GET(PaymentMethodMaster.endPoints.getList + "?", {
      masterType: MasterTypes.PaymentMethod,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const paymentOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      paymentDetails: paymentOptions,
    }));
  };
  const GetStudents = async () => {
    // setIsLoading(true);
    let res = await api.GET(EnquiryEndPoint.getList + "?", {
      type: CandidateRequestType.Student,
      length: 250,
    });

    const studentOptions =
      res?.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.fullName,
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      studentDetails: studentOptions,
    }));
  };
  //console.log(masterOptions,"MasterOptionsStudent");
  
  const GetFeesType = async () => {
    let res = await masterApi.GET(FeesTypeMaster.endPoints.getList + "?", {
      masterType: MasterTypes.FeesType,
      // start: 0,
      // length: 15,
      search: "",
      sortCol: "Name",
      sortDir: "ascend",
    });
    const feesTypeOptions =
      res.result?.items?.map((item: any) => ({
        value: item.id,
        label: item.name,
        isRefundable:item?.isRefundable||false
      })) || [];
    setMasterOptions((prevOptions: any) => ({
      ...prevOptions,
      feesTypeOption: feesTypeOptions,
    }));
  };
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        batch: any;
      } = {
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
      setStremData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items
      );
    } else {
      message.error(res?.message);
    }
  };

  useEffect(() => {
    GetFeesType();
    GetStudents();
    GetPaymentMethods();
    getMaster();
  }, []);

  const handleClick = async () => {
    form.validateFields().then(async (formValues: any) => {
      let val = {
        ...formValues,
        id: props?.propsData?.id,
        student: {
          id: formValues?.student?.value,
          name: formValues?.student?.label,
        },
        medium: {
          id: formValues?.medium?.value,
          name: formValues?.medium?.label,
        },
        feesType: {
          id: formValues?.feesType?.value,
          name: formValues?.feesType?.label,
        },
        stream: {
          id: formValues?.stream?.value,
          name: formValues?.stream?.label,
        },
        batch: {
          id: formValues?.batch?.value,
          name: formValues?.batch?.label,
        },
        index: props?.propsData?.key,
        IsRefundable: isRefund
      };
      console.log("refund",formValues)
      setIsLoading(true);
      await HTTPSCalls.POST(
        val?.id
          ? HTTPSCalls.ENDPOINTS.PAYMENT_UPDATE
          : HTTPSCalls.ENDPOINTS.PAYMENT_ADD,
        {},
        {
          ...val,
        }
      ).then((res) => {
        if (res?.message) {
          message.error(res?.message);
          setIsLoading(false);
          // props?.onClose(false);
        } else {
          message.success("Submitted Successfully");
          //   setReset((prev: number) => prev + 1);
          setIsLoading(false);
          props?.onClose(true);
        }
      });
      console.log(val,"TestData");
      
    });
  };

  return (
    <>
      <Drawer
        title="Fees"
        width={500}
        onClose={props?.onClose}
        open={true}
        extra={
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              props?.onClose();
            }}
          ></Button>
        }
        // bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "end" }}>
            <Space>
              <Button
                onClick={() => {
                  props?.onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleClick();
                }}
                type="primary"
                loading={isLoading}
              >
                Submit
              </Button>
            </Space>
          </div>
        }
      >
        <div style={{ padding: "20px" }}>
          <Form
            form={form}
            layout="horizontal"
            wrapperCol={{ md: 18 }}
            labelCol={{ md: 6 }}
            labelAlign="left"
          >
            <Form.Item
              name="student"
              rules={[
                { required: true, message: "Please input your Details!" },
              ]}
              label="Student"
            >
              <Select
                disabled={!!props?.propsData?.id}
                showSearch
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.studentDetails}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please input your Date!" }]}
            >
              <DatePicker
                disabled={!!props?.propsData?.id}
                format="DD-MMM-YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="feesType"
              rules={[
                { required: true, message: "Please Select Fees Type!" },
              ]}
              label="Fees Type"
            >
              <Select
                showSearch
                disabled={!!props?.propsData?.id}
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.feesTypeOption}
                onSelect={(text,record)=>{
                  setIsRefund(record?.isRefundable)
                }}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              name="medium"
              rules={[{ required: true, message: "Please input your Medium!" }]}
              label="Medium"
            >
              <Select
                disabled={!!props?.propsData?.id}
                showSearch
                style={{ width: "100%" }}
                labelInValue
                options={masterOptions?.paymentDetails}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="amount"
              rules={[
                {
                  type: "number",
                  min: 0,
                  message: "Amount can't be 0 or negative",
                },
                { required: true, message: "Please input your Amount!" },
                // {
                //   validator: (rule: any, value: any) => {
                //     console.log();

                //     if (value < 0) {
                //       return Promise.reject("Amount can't be <=0")
                //     }
                //     else
                //       return Promise.resolve()
                //   }
                // }
              ]}
              label="Amount"
            >
              <InputNumber
                disabled={!!props?.propsData?.id}
                controls={false}
                name="amount"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item name="transactionId" label="Transaction Id">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="mediumDetails"
              rules={[
                { required: true, message: "Please input your Details!" },
              ]}
              label="Details"
            >
              <TextArea />
            </Form.Item>
            {/* <Form.Item name="stream" label="Stream">
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Stream"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={streamData}
              ></Select>
            </Form.Item> */}
            {/* <Form.Item name="batch" label="Batch">
              <Select
                // loading={isLoading}
                popupClassName="cit-select-box"
                className="me-2"
                labelInValue
                placeholder={"Select Batch"}
                allowClear
                showSearch
                defaultActiveFirstOption
                style={{ width: "100%" }}
                options={masterData?.batch}
              ></Select>
            </Form.Item> */}
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default AddEditFees;
