import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { HolidaysMaster } from "../../ApiUrls/URLS";
import { MasterTypes } from "../../Constant/Enums";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface IHoliday {
  key: string;
  sNo: number;
  id: string;
  name: string;
  fromTo: { from: string; to: string };
}

const api = new ApiCalls(HolidaysMaster.endPoints, HolidaysMaster.prefix);

const HolidaysTable = (props:any) => {
  const navigate = useNavigate();
  const {data}=props;
  // Ensure only top 7 holidays are shown
 const topHolidays = data?.slice(0, 7);
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      text: "center",
      key: "sNo",
      width: "9%",
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: "Holidays",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "From",
      dataIndex: "fromTo",
      key: "From",
      width: "25%",
      render: (text: any) => <p>{dayjs(text?.from).format("DD MMM YYYY")}</p>,
    },
    {
      title: "To",
      dataIndex: "fromTo",
      key: "To",
      width: "25%",
      render: (text: any) => <p>{dayjs(text?.to).format("DD MMM YYYY")}</p>,
    },
  ];

  const [allHoliday, setAllHoliday] = useState<IHoliday[]>([]);
  const [listParams, setListParams] = useState({
    search: "",
    sortCol: "Name",
    sortDir: "ascend",
  });
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 7 });
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  return (
    <>
      <Table
        className="mt-2 dashBoardTable-Font"
        style={{
          width: "100%",
          borderBottom: "none",
          maxHeight: 730,
          fontSize: "13px",
          overflow: "auto",
          minHeight: 295,
        }}
        size="small"
        pagination={false}
        dataSource={topHolidays}
        columns={columns}
        loading={pageLoader}
      />
    </>
  );
};

export default HolidaysTable;
