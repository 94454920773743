import * as React from "react";
import { useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import SunEditor from "suneditor-react";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface AddEditPrivacyPolicyIn {
  setTrigger: any;
  openDrawer: any;
  privacyPolicyId: string | undefined;
  SetDrawerClose: any;
  recordData: any;
}

const AddEditPrivacyPolicy = (props: AddEditPrivacyPolicyIn) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  console.log(props.recordData.body);
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const saveData =async () => {
    try {
        setLoading(true);
        let formData= form.getFieldsValue()
        let res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.POST_PRIVACY_POLICY,{
        },{
          id: props.recordData.id,
          name:formData?.name,
          body:formData?.body,
        })
        onClose()
    } catch (error) {
      console.log(error);
    } finally {
      props.setTrigger((prev: boolean) =>!prev)
        setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.openDrawer) {
      if (props?.privacyPolicyId &&  props?.recordData) {
        form.setFieldsValue({
          name: props.recordData.name,
          body: props.recordData.body,
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.openDrawer, props?.recordData]);

  const onClose = () => {
    props?.SetDrawerClose(false);
    form.resetFields();
  };
  const editor = React.useRef();
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  return (
    <>
      <Drawer
        title={props?.privacyPolicyId ? "Edit Privacy Policy" : "Add Privacy Policy"}
        placement="right"
        width={1000}
        maskClosable={false}
        onClose={onClose}
        closeIcon={false}
        open={props?.openDrawer}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={onClose}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={onClose}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={loading}
              className="btn-m-sm"
              onClick={() => saveData()}
            >
              {props?.privacyPolicyId ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            name="privacyPolicyForm"
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            size="small"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter  name!" }]}
            >
              <Input
                autoFocus
                placeholder="Enter  Name"
                size="middle"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item name="body" label="" className="marginbtm" rules={[{required:true,message:"Please add content here"}]}>
            <SunEditor
              setContents= {props?.privacyPolicyId ? props?.recordData?.body : ""}
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["bold", "underline", "italic", "strike"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],
                  // ["table", "horizontalRule", "link", "image", "video"],
                  ["print"],
                  ["removeFormat"],
                ],
                defaultTag: "div",
                font: [
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "Tahoma",
                  "Trebuchet MS",
                  "Verdana",
                ],
                fontSize: [
                  8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 36,
                ],
                defaultStyle: "font-family: Arial; font-size: 14px;", // Set default style
                showPathLabel: false,
              }}
              height="600px"
            />
          </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default AddEditPrivacyPolicy;
