import ReactDOM from "react-dom/client";
import "./index.css";
import "./Custom.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./Context/UserContext";
import ApplicationState from "./Context/ApplicationState";
import EnquiryFormStu from "./Common/EnquiryFormFillByStu/WebsiteEnquiry";
import EnquiryLead from "./Enquiry/EnquiryLead";
import WebsiteEnquiry from "./Common/EnquiryFormFillByStu/WebsiteEnquiry";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { persistor, store } from "./Store/store";
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider
  // theme={{ token: { fontFamily: "Poppins" } }}
  theme={{
    components: {
      Menu: {
        darkSubMenuItemBg: " rgb(253, 12, 148)",
        darkItemBg: "rgb(39, 0, 41)",
        colorText: "#001440",
        // itemBg: "#EBF3FC",
      },
      // Menu: {
      // radiusItem: 0,
      // itemBorderRadius: 0,
      // radiusSubMenuItem: 0,
      // subMenuItemBorderRadius: 0,
      // itemColor: "white",
      // itemHoverColor: "white",
      // horizontalItemHoverColor: "#1677ff",
      // groupTitleColor: "white",
      // itemSelectedColor: "white",
      // horizontalItemSelectedColor: "#1677ff",
      // itemHoverBg: "#002a52",
      // itemActiveBg: "#1F77FB",
      // subMenuItemBg: "#000c17",
      // itemSelectedBg: "#1F77FB",
      // activeBarHeight: 2,
      // itemMarginInline: 0,
      // itemHeight: 35,
      // itemMarginBlock: 8,
      // itemPaddingInline: 16,
      // horizontalLineHeight: "46px",
      // iconSize: 17,
      // iconMarginInlineEnd: 20,
      // groupTitleFontSize: 12,
      // fontSize: 14,
      // fontWeightStrong: 800,
      // darkItemHoverBg: "#002a52",
      // darkItemColor: "white",
      // },

      Table: {
        // fontSize: 14,
        fontWeightStrong: 500,
        // lineHeight: 1,
        // lineWidth: 0,
        // lineType: "dashed",
        padding: 6,
        paddingContentVerticalLG: 6,
        algorithm: true,
        colorFillAlter: "#F8FAFE",

        // borderRadius: 0,
        // algorithm: true,
      },
      Badge: {
        colorSuccess: "#40d824",
        colorWarning: "#FF9100",
        colorError: "#FF0000",
        algorithm: true,
      },
      Button: {
        fontSize: 13,
        // borderRadius: 4,
      },
      // Input: {
      //   colorTextPlaceholder: "#1B1A1A",
      //   colorFillAlter: "transparent",

      //   fontSize: 13,
      //   // controlHeight: 28,
      //   // borderRadius: 1,
      //   algorithm: true,
      // },
      DatePicker: {
        colorTextPlaceholder: "#1B1A1A",
        algorithm: true,
        // fontSize: 13,
        // controlHeight: 28,
        // borderRadius: 1,
      },
      // Select: {
      //   colorTextPlaceholder: "#1B1A1A",
      //   fontSize: 13,
      //   // fontSize: 14,
      //   // controlHeight: 28,
      //   // borderRadius: 1,
      //   algorithm: true,
      //   fontWeightStrong: 600,
      // },
      Pagination: {
        borderRadius: 1,
        algorithm: true,
        itemSize: 28,
        fontSize: 13,
        margin: 4,
      },
    },

    // token: { fontFamily: "Segoe UI", fontSize: 14 },
    token: {
      // fontFamily: "Open Sans, sans-serif",
      fontFamily:  "Roboto, sans-serif",
      // fontFamily: "Inter",
      // fontFamily: "Verdana",
      // fontFamily: "Readex Pro",
    },
  }}
>
<Provider store={store}>
<PersistGate loading={null} persistor={persistor}>
  <UserProvider>
    <ApplicationState>
      <BrowserRouter>
        <>
        <Routes>
          <Route path="*" element={<App />} />
          <Route  path="/webEnquiry/:id" element={<WebsiteEnquiry/>} />
          </Routes>
        </>
      </BrowserRouter>
    </ApplicationState>
  </UserProvider>
  </PersistGate>
  </Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
