import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { Footer } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import HTTPSCalls from "../Services/HTTPCalls";
import { IApiResponse } from "../Utilities/ApiUtility.axios";


const ChangePasswordModal = ({ openModel, setOpenModel }: any) => {
  const [form] = useForm();
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  // const handleOk = () => {
  //     // setModalText('The modal will be closed after two seconds');
  //     setConfirmLoading(true);
  //     setTimeout(() => {
  //       setOpen(false);
  //       setConfirmLoading(false);
  //     }, 2000);
  //   };

  const handleCancel = () => {
    setOpenModel((pre: any) => !pre);
    console.log(openModel, "state=-=-=-");
  };

//   useEffect(()=>{
     
//   },[])
// const handleSubmit=()=>{
//   form.validateFields().then((values)=>{
//      console.log(values,"consolevalues")
//      form.resetFields()
//      HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.RESET_PASSWORD,+"?",{})
//   }).then((response:IApiResponse)=>{
//     if(response.status){
//         console.log(respo)
//     }
//   }) 
//   ;
// }
  return (
    <>
      <Modal
        title="Change your password"
        open={openModel}
        // onOk={handleSubmit}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="currentPassword" label="Current Password" rules={[{required:true, message:"Please enter current password"}]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item name="newPassword" label="New Password" rules={[{required:true, message:"Please enter new password"}]}>
            <Input.Password/>
          </Form.Item>
          <Form.Item name="confirmPassword" label="Confirm Password" rules={[{required:true, message:"Please confirm your password"}]}>
            <Input.Password/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
