import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Row, Select, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { MasterTypes, RoleTypes, RoleTypesOptions } from "../../Constant/Enums";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import ImageUploader from "../../UI/ImageUploader/ImageUploader";
import { AccessCodes } from "../../Common/Sidemenus";

interface IEditUser {
  id: string;
  detail:any;
  open: boolean;
  editDetails:any;
  onDismiss: (rec: boolean) => void;
}
interface UserImage {
  name: string;
  id: string;
  path: string;
  contentType: string;
}

const EditUser = (props: IEditUser) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  // const [open, setOpen] = useState<boolean>(true)
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [clearImage, setClearImage] = useState(false);
  const [userImage, setUserImage] = React.useState<UserImage[]>([]);
  const [roleOptions, setRoleOptions] = useState<any>([]);
  const [rightOptions, setRightOptions] = useState<any>([]);
  const [rightsSelect,setRightsSelect]= useState<any>();

  const [form] = Form.useForm();
  useEffect(() => {
    getRoleList();
    if (props?.id) {
      setLoading(true);
      setBtnLoading(true);
      HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_USER_BY_ID + "?", {
        id: props?.id,
      }).then((res: IApiResponse) => {
        if (res?.result) {
          let setRights;
          if (res?.result?.rights) {
            setRights = Object.entries(res?.result?.rights)
              .filter(([key, value]) => value !== null) // Filter out entries where value is null
              .map(([key, value]) => ({
                label: key,
                value: value,
              }));
          }
          
          console.log("roleType",res?.result)
          form.setFieldsValue({
            ...res?.result,
            name: res?.result?.name?.first,
            right:setRights,
            RoleTypes:RoleTypesOptions[res?.result?.roleType]
          });
          setLoading(false);
          setBtnLoading(false);
          console.log(setRights,"result",res?.result);
          
        } else {
          message.error(res?.message);
          setLoading(false);
          setBtnLoading(false);
        }
      });
    }
  }, []);

  const resetForm = () => {
    setClearImage(!clearImage);
    setUserImage([]);
  };
  const saveData = async () => {
    form.validateFields().then(async (val: any) => {
      let rightsObj :any={}
      rightsSelect?.forEach((ele:any)=>{
        rightsObj[ele?.label]=ele?.value 
      })
      setBtnLoading(true);
      let values: any = {
        ...val,
        id: props?.id,
        updateId: props?.id,
        callerId:val?.callerId,
        agentId:val?.agentId,
        name: {
          title: "",
          first: val?.name,
          last: "",
        },
        roleType:val?.roleType,
        rights:rightsObj,
        userImage:
          userImage?.length > 0
            ? userImage?.map((arrayItem: any) => {
                return {
                  name: arrayItem?.name,
                  id: "",
                  path: arrayItem?.thumbUrl,
                  contentType: arrayItem?.type,
                };
              })[0]
            : {
                name: "",
                id: "",
                path: "",
                contentType: "",
              },
      };
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.SAVE_USER,
        {},
        values
      );
      if (res?.result) {
        const userAction = props?.id ? "User Update" : "User added";
        setTimeout(() => {
          message.success(`${userAction} successfully`);
        }, 500);
        setBtnLoading(false);
        resetForm();
        form.resetFields();
        props?.onDismiss(true);
      } else {
        message.error(res?.message);
        setBtnLoading(false);
      }
      setBtnLoading(false);
      console.log(values,"UserDataEditUserComponent",rightsObj);
    });
  };
  const getRoleList = async (val: string = "") => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
      {
        type: MasterTypes.Role,
        start: 0,
        length: 15,
        search: val,
      }
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      setRoleOptions(
        result?.map((x: any) => ({ label: x?.name, value: x?.name }))
      );
    } else {
      message.error(res?.message);
    }
  };
  useEffect(() => {
    const convertedArray = Object.entries(AccessCodes).map(([key, value]) => ({
      label: key,
      value: value,
    }));  
    setRightOptions(convertedArray);
  }, []);

  const handleChange = (value: string[],option:any) => {
    console.log("value1",value,option);
    setRightsSelect(option);
  };

  return (
    <>
      <Drawer
        title={props?.id ? "Edit User" : "Add User"}
        placement="right"
        width={500}
        maskClosable={false}
        onClose={() => {
          props?.onDismiss(false);
        }}
        closeIcon={false}
        open={props?.open}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            className=""
            onClick={() => {
              props?.onDismiss(false);
            }}
          />
        }
        footer={
          <Row justify="end">
            <Button
              onClick={() => {
                props?.onDismiss(false);
              }}
              disabled={btnLoading}
              className="ttp-btn-light btn-m-sm"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-m-sm"
              loading={btnLoading}
              onClick={() => saveData()}
            >
              {props?.id ? "Update" : "Save"}
            </Button>
          </Row>
        }
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
          style={{ height: "100%" }}
        >
          <Form
            form={form}
            className="DrawerPadding"
            colon={false}
            autoComplete="off"
            style={{ padding: 16 }}
            {...formItemLayout}
            labelAlign="left"
            requiredMark={false}
            initialValues={{ status: 0 }}
          >
            <Form.Item
              label="First name"
              name={"name"}
              rules={[{ required: true, message: "Please enter first name!" }]}
            >
              <Input
                placeholder="Enter First Name"
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name={"email"}
              rules={[
                { required: true, message: "Please enter email!" },
                { type: "email", message: "Please enter valid email!" },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              label="Role"
              name={"role"}
              rules={[{ required: true, message: "Please select role!" }]}
            >
              <Select placeholder="Select role" options={roleOptions} />
            </Form.Item>
            <Form.Item label="Status" name={"status"}>
              <Select
                placeholder="Select Status"
                options={[
                  { label: "Active", value: 0 },
                  { label: "InActive", value: 1 },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Role Type"
              name={"roleType"}
              rules={[{ required: true, message: "Please select role type!" }]}
            >
              <Select placeholder="Select role type" options={RoleTypesOptions} />
            </Form.Item>
            {/* select role Right */}
            <Form.Item
              label="Right"
              name={"right"}
              rules={[{ required: true, message: "Please select Right!" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select right"
                onChange={(value,option)=>handleChange(value,option)}
                options={rightOptions}
              />
            </Form.Item>
            <Form.Item label="Mobile No." name={"phoneNumber"}>
              <Input placeholder="Enter Mobile No." />
            </Form.Item>
            <Form.Item label="Agent Soft Id" name={"agentId"}>
              <Input placeholder="Enter Agent Soft Id." />
            </Form.Item>
            <Form.Item label="CallerId Number" name={"callerId"}>
              <Input placeholder="Enter CallerId number" />
            </Form.Item>
            <Form.Item label="Address" name={"address"}>
              <TextArea rows={4} placeholder="Enter Address" />
            </Form.Item>
            <Form.Item label="Image" name={"userImage"}>
              <ImageUploader
                defaultImages={[]}
                setFileUrls={setUserImage}
                limit={1}
                shouldPreview={true}
                children={undefined}
                shouldCrop={false}
                clearImage={clearImage}
                source={form.getFieldValue("userImage")?.path}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditUser;
