export enum MasterTypes {
  Unknown,
  Gender,
  Category,
  Curriculum,
  Stream,
  Classes,
  PaymentMethod,
  Credential,
  Batch,
  Documents,
  Holidays,
  Status,
  Medium,
  Course,
  Subjects,
  Semester,
  Department,
  Role,
  FeesType,
  Counselor,
  Source,
  AssignTo,
  Reason,
  Platform,
  Reminder,
  Task,
  MailTemplates,
  Session,
  Room,
  Schema,
  Section,
  AssessmentCategory,
  GradeScale,
  FacultyMapWithDept,
  Rubric,
  SubjectTypes,
  MandatoryDocumentType,
  Users
}
export const IndianState: any = [
  { label: "None", value: 0 },
  { label: "Andaman and Nicobar Islands", value: 1 },
  { label: "Andhra Pradesh", value: 2 },
  { label: "Arunachal Pradesh", value: 3 },
  { label: "Assam", value: 4 },
  { label: "Bihar", value: 5 },
  { label: "Chandigarh", value: 6 },
  { label: "Chhattisgarh", value: 7 },
  { label: "Dadra and Nagar Haveli", value: 8 },
  { label: "Daman and Diu", value: 9 },
  { label: "Delhi", value: 10 },
  { label: "Goa", value: 11 },
  { label: "Gujarat", value: 12 },
  { label: "Haryana", value: 13 },
  { label: "Himachal Pradesh", value: 14 },
  { label: "Jammu and Kashmir", value: 15 },
  { label: "Jharkhand", value: 16 },
  { label: "Karnataka", value: 17 },
  { label: "Kerala", value: 18 },
  { label: "Lakshadweep", value: 19 },
  { label: "Madhya Pradesh", value: 20 },
  { label: "Maharashtra", value: 21 },
  { label: "Manipur", value: 22 },
  { label: "Meghalaya", value: 23 },
  { label: "Mizoram", value: 24 },
  { label: "Nagaland", value: 25 },
  { label: "Odisha", value: 26 },
  { label: "Puducherry", value: 27 },
  { label: "Punjab", value: 28 },
  { label: "Rajasthan", value: 29 },
  { label: "Sikkim", value: 30 },
  { label: "Tamil Nadu", value: 31 },
  { label: "Telangana", value: 32 },
  { label: "Tripura", value: 33 },
  { label: "Uttar Pradesh", value: 34 },
  { label: "Uttarakhand", value: 35 },
  { label: "West Bengal", value: 36 },
];

export enum ApprovalStatus {
  Rejected = 0,
  OptionalOut = 1,
  OnHold = 2,
  Enrolled = 3,
  InterestedOne = 4,
  All = 5,
}

export enum LanguageProficiency {
  Beginner = 0,
  Intermediate = 1,
  Advanced = 2,
}

export enum ResponseType {
  IdName,
  Complete,
  Filtered,
}

export enum CandidateRequestType {
  Undefine,
  Enquire,
  Student,
}

export enum EnquiryType {
  Undefined,
  Website,
  Application,
}

// ProcessStepsEnum
export enum ProcessStepsEnum {
  Undefined = 0,
  Website = 1,
  New = 2,
  Counseling = 3,
  Registration = 4,
  Documents = 5,
  EmailInvoice = 6,
  Verification = 7,
  LSBU = 8,
  Enroll = 9,
}

export const ProcessStepsOptions = [
  { label: "New", value: 2 },
  { label: "Counseling", value: 3 },
  { label: "Registration", value: 4 },
  { label: "Documents", value: 5 },
  { label: "EmailInvoice", value: 6 },
  { label: "Verification", value: 7 },
  { label: "LSBU", value: 8 },
  { label: "Enroll", value: 9 },
];

// WebsiteSubSteps
export enum WebsiteSubSteps {
  SendForm,
  Received,
}

export enum NewSubSteps {
  Waiting = 0,
  Confirm = 1,
  Reject = 2,
}

export enum CounselingSubSteps {
  Waiting = 0,
  Confirm = 1,
  Reject = 2,
}

export enum RegistrationSubSteps {
  Waiting = 0,
  Proceed = 1, // Confirm
  Reject = 2,
}

export enum DocumentsSubSteps {
  Submission = 0, // Waiting
  VerifyDocuments = 1, // Confirm
  Reject = 2,
  Acknowledgement = 3,
}

export enum EmailInvoiceSubSteps {
  Waiting = 0,
  SendInvoice = 1, // Confirm
  Reject = 2,
}

export enum VerificationSubSteps {
  Waiting = 0,
  VerifyDocuments = 1, // Confirm
  Reject = 2,
  Acknowledgement = 3,
}

// LsbuSubSteps
export enum LsbuSubSteps {
  Waiting = 0,
  ReceivedCredentials = 1,
  Reject = 2,
  ShareCVDocs = 3,
  ReceivedStudyContact = 4,
}

export enum CommonEnqListType {
  all = 1,
  newLead,
  unAssignedLead,
  activity,
  missedActivity,
}

export enum AttachmentsCategory {
  Undefined,
  Note,
  CallLog,
  Email,
  Meeting,
  Task,
  StudentDocuments,
  EmailInvoice,
  Lsbu,
}
export enum CommunicationType {
  Unknown,
  Note,
  CallLog,
  Mail,
  Meeting, // Correcting the typo from "Metting" to "Meeting"
  Task,
}

export enum AttachmentsInnerCategory {
  Undefined = 0,
  CV,
  StudyContact,
}

export const CategoryAttachmentName: any = {
  "0": "Undefined",
  "1": "Note",
  "2": "Call Log",
  "3": "Email",
  "4": "Meeting",
  "5": "Task",
  "6": "Student Documents",
  "7": "Email Invoice",
  "8": "LSBU",
};

export enum MeetingType {
  Undefined,
  Online,
  Offline,
}
export enum CallType {
  Undefined,
  Incoming,
  Outgoing,
}

export enum OutcomeType {
  Undefined,
  Interested,
  LeftMessage,
  NoResponse,
  Notabletoreach,
  DNC,
}
export const meetingType: any = {
  0: "Undefined",
  1: "Online",
  2: "Offline",
};
export const callType: any = {
  0: "Undefined",
  1: "Incoming",
  2: "Outgoing",
};

export const outcomeType: any = {
  0: "Undefined",
  1: "Interested",
  2: "Left Message",
  3: "No response",
  4: "Not able to reach",
  5: "DNC",
};
export const responseStatus: any = {
  0: "Undefined",
  1: "Interested",
  2: "Not Interested",
  3: "DNP",
  4: "Undefined",
};
export const CenterVisited: any = {
  0: "None",
  1: "Yes",
  2: "No",
};
export const CategoryAttachmentOptions: any = [
  { label: "All", value: 0 },
  { label: "Note", value: 1 },
  { label: "Call Log", value: 2 },
  { label: "Email", value: 3 },
  { label: "Meeting", value: 4 },
  { label: "Task", value: 5 },
  { label: "Student Documents", value: 6 },
  { label: "Email Invoice", value: 7 },
  { label: "LSBU", value: 8 },
];
export const outcomeTypeOptions: any = [
  { label: "Interested", value: 1 },
  { label: "Left Message", value: 2 },
  { label: "No response", value: 3 },
  { label: "Not able to reach", value: 4 },
  { label: "DNC", value: 5, className: "dnc-option" },
  { label: "Pending", value: 6 },
  { label: "Done", value: 7 },
];
export const meetingTypeOptions: any = [
  { label: "Online", value: 1 },
  { label: "Offline", value: 2 },
];

export enum UserLogActions {
  Undefined = 0,
  Insert = 1,
  Update = 2,
  Delete = 3,
  DeleteMany = 4,
  Approved = 5,
  EnquiryReject = 6,
  ProcessStepChanged = 7,
}
export const CallTypeOption: any = [
  { label: "Incoming", value: 1 },
  { label: "Outgoing", value: 2 },
];

export const ProcessStepsEnumName: any = {
  1: "Interested",
  2: "New",
  3: "Counseling",
  4: "Registration",
  5: "Documents",
  6: "Email Invoice",
  7: "Verification",
  8: "LSBU",
  9: "Enroll",
};
export enum DataTypeEnum {
  Undefined,
  DateTime,
  String,
  Number,
  Boolean,
}
export const nationalityOption = [
  {
    label: "Indian",
    value: "Indian",
  },
];
export let StudyModeObj: any = {
  0: "Regular",
  1: "Private",
};
export let LevelObj: any = {
  0: "Beginner",
  1: "Intermediate",
  2: "Advanced",
};

export let languageProficiency: any = {
  0: "Beginner",
  1: "Intermediate",
  2: "Advanced",
};

export enum DateType {
  Undefined,
  EnrollDate,
}

export const processStepsOptions = [
  // { label: "Website", value: 1 },
  { label: "New", value: 2 },
  { label: "Counseling", value: 3 },
  { label: "Registration", value: 4 },
  { label: "Documents", value: 5 },
  { label: "EmailInvoice", value: 6 },
  { label: "Verification", value: 7 },
  { label: "LSBU", value: 8 },
  { label: "Enroll", value: 9 },
];

export const ResponseStatusOption = [
  // {
  //   value: 0,
  //   label: "None"
  // },
  {
    value: 1,
    label: "Interested",
  },
  {
    value: 2,
    label: "Not Interested",
  },
  {
    value: 3,
    label: "DNP",
  },
  {
    value: 4,
    label: "Follow Up",
  },
];
export const CenterVisitedOptions: any = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 },
];
export const categoryType: any = {
  0: "Undefined",
  1: "ClassSchedule",
  2: "ExamSchedule",
  3: "GeneralSchedule",
};
export const CategoryTypeOption: any = [
  { label: "ClassSchedule", value: 1 },
  { label: "ExamSchedule", value: 2 },
  { label: "GeneralSchedule", value: 3 },
];

export enum ScheduleCategory {
  Undefined,
  ClassSchedule,
  ExamSchedule,
  GeneralSchedule,
}

export const GradingScaleOptions: any = [
  { label: "Grades", value: "Grades" },
  { label: "Rubric", value: "Rubric" },
  { label: "Percentages", value: "Percentages" },
  { label: "ActualPointMarks", value: "ActualPointMarks" },
];

export enum RecordStatusType {
  Undefined = 0,
  Drafted = 1,
  Published = 2,
  Deleted = 3,
  Approved = 4,
  Pending = 5,
}

export const FeesType = {
  Paid: "Paid",
  Due: "DueStudent",
  DueStudentLeft:"DueStudentLeft"
};

export enum RoleTypes{
  undefined,
  student,
}

export enum leaveStatusEnum  {
  Pending,
  Approve,
  Cancel,
  Reject,
};

export const  leaveStatusObject:Record<string, number> =   {
  "Pending":0,
  "Approve":1,
  "Cancel":2,
  "Reject":3,
};

export const LeaveTypeEnum:any={
  0:"Casual Leave",
  1:"Sick Leave"
}
export const RoleTypesOptions=[
  {
    value:RoleTypes.undefined,
    label:"For Web",
},
{
  value:RoleTypes.student,
  label:"Student For App"
}
]