import { Button, Col, Row, Table, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { CalendarOutlined, CheckOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";
import dayjs from "dayjs";
import LeaveReqModal from "./LeaveReqModal";
import { leaveStatusEnum, leaveStatusObject, LeaveTypeEnum } from "../../Constant/Enums";
const LeaveRequests = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [leave, setLeave] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tableRecord,setTableRecord]=useState<any>([]);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "0.7%",
      key: "sno",
      render: (text: any, record: any, index: any) => (
        <>
          <p>{index + 1}</p>
        </>
      ),
    },
    // {
    //   title: "Student Id",
    //   dataIndex: "id",
    //   render: (text, record) => <span style={{ cursor: "pointer" }}>{""}</span>,
    //   width: "2%",
    //   ellipsis: true,
    // },
    {
      title: "Student",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text, record) => <span>{record?.createdBy?.userName}</span>,
      width: "2%",
      ellipsis: true,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (text, record) => <span>{record?.batch?.name}</span>,
      width: "2%",
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <span>{dayjs(record?.createdBy?.date).format("DD/MM/YYYY")}</span>
      ),
    //   (
           
        // <span>{new Date(record?.date).toLocaleDateString()}</span>
    //   ),
      width: "2%",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
      render: (text:any) => (
        <span>{text?.name}</span>
      ),
      width: "2%",
    },
    {
        title: "Days",
        dataIndex: "days",
        key: "days",
        render: (from: any, record: any) => {
          const fromDate = dayjs(from);
          const toDate = dayjs(record.to); 
          let daysDifference = toDate.diff(fromDate, 'day');
          if (daysDifference === 0) {
            daysDifference = 1;
          } 
          return <span>{daysDifference}</span>;
        },
        width: "2%",
      },
    {
      title: "Date",
      dataIndex: "from",
      render: (date:any) => <span>{dayjs(date).format("DD/MM/YYYY")}</span>,
      width: "2%",
    },
    {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        render: (text, record) => <span>{record?.reason}</span>,
        width: "2%",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          const status:leaveStatusEnum = text;
          let color = "";
      
          switch (status) {
            case leaveStatusEnum.Approve:
              color = "#87d068";  // Approve
              break;
            case leaveStatusEnum.Pending:
              color = "#4F93A9";     // Pending
              break;
            case leaveStatusEnum.Cancel:
              color = "#FFA500";   // Cancel
              break;
            case leaveStatusEnum.Reject:
              color = "#f50";      // Reject
              break;
            default:
              color = "gray";     // Default color for unknown statuses
          }
      
          return <Tag style={{width:"70%",textAlign:"center"}} color={color}>{leaveStatusEnum[status]}</Tag>;
        },
        width: "1.5%",
      },
      {
        title:"Action",
        key:"action",
        width:"1.5%",
        render:(text,record)=>(<><Button size="small" onClick={()=>{showModal();setTableRecord(record)}}>Action</Button></>)
      }
  ];

  const getLeaveRequest = async () => {
    try {
      await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.LEAVE_APPLICATION_ADMIN,
        {}
      ).then((res: IApiResponse) => {
        setLoading(true);

        if (res?.result) {
          setLeave(res.result);
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLeaveRequest();
  }, [refresh]);
  return (
    <>
      <Row
        className="roundedCornerSmall bg-white"
        style={{ padding: "2px 16px 16px 16px" }}
      >
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: (
                  <span>
                    <CalendarOutlined /> Leave Application
                  </span>
                ),
                children: (
                  <>
                    <Row>
                      <Col span={24}>
                        <Table
                          //   className="cit-table"
                          size="small"
                          columns={columns}
                          dataSource={leave}
                        />
                      </Col>
                    </Row>
                  </>
                ),
              },
 
              //   {
              //     key: "2",
              //     label: (
              //       <span>
              //         <CheckOutlined /> Correction
              //       </span>
              //     ),
              //     children: (
              //       <>
              //         <Col span={24}>
              //           <Table
              //             className="cit-table"
              //             size="small"
              //             //  columns={}
              //             //  dataSource={}
              //           />
              //         </Col>
              //       </>
              //     ),
              //   },
            ]}
          />
        </Col>
      </Row>
      {open&&<LeaveReqModal open={open} handleCancel={handleCancel} tableRecord={tableRecord} setRefresh={setRefresh}/>}
    </>
  );
};

export default LeaveRequests;
