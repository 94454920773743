import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface UserRights {
  enquiries?: string;
  students?: string;
  curriculums?: string;
  streams?: string;
  classes?: string;
  paymentMedium?: string;
  credentials?: string;
  documents?: string;
  holidays?: string;
  status?: string;
  medium?: string;
  role?: string;
  attendance?: string;
  fees?: string;
  settings?: string;
  feesType?: string;
  lms?: string;
  assessment?: string;
}

interface UserState {
  token: string;
  name: string;
  tokenExpiry: number;
  id: string;
  role: string;
  email: string;
  userName: string;
  status: number;
  roles: string[];
  rights: UserRights | null;
}

const initialState: UserState = {
  token: "",
  name: "",
  tokenExpiry: 0,
  id: "",
  role: "",
  email: "",
  userName: "",
  status: 0,
  roles: [],
  rights: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.tokenExpiry = action.payload.tokenExpiry;
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.userName = action.payload.userName;
      state.status = action.payload.status;
      state.roles = action.payload.roles;
      state.rights = action.payload.rights;
    },
    resetUserData: (state) => {
      Object.assign(state,initialState)
    },
  },
});

export const { setUserData, resetUserData } = userSlice.actions;
export const userData = (state: RootState) => state.user;
export default userSlice.reducer;
