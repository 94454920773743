import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  QuestionOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  ApprovalStatus,
  CandidateRequestType,
  MasterTypes,
  ProcessStepsEnumName,
  RecordStatusType,
  ResponseType,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import EnquiryForm from "../../Enquiry/EnquiryForm";
import dayjs from "dayjs";
import StudentDetails from "./StudentDetails";
import AttendanceService from "../../Services/AttendanceService";
// import "../../Common/Common.css";
import "../../Custom.css";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import { EnquiryEndPoint } from "../../ApiUrls/URLS";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import New_Student_Form from "../../Enquiry/New_Student_Form";
import CardModel from "./CardModel";
import { IdName } from "../../Types";
import { ViewType } from "../../component/EnquiryLeadModel/EmailEnquiry";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");

// import Search from "antd/es/input/Search";
const { Option } = Select;
const { Search } = Input;
let userType: any = JSON.parse(localStorage.getItem("tp-user") + "")?.role;
interface Item {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}
interface IEnquiryTable {
  id: string;
  key: number;
  name: string;
  fatherName: string;
  email: string;
  dob: string;
  category: string;
  passoutYear: number;
  curriculum: string;
  stream: string;
  reference: string;
  mobileNumber: string;
}

const StudentsList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IEnquiryTable[]>([]);
  const [basicDetailFrm, setBasicDetailFrm] = useState<any>({});
  const [credentialFrm, setCredentialFrm] = useState<any>({});
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState<boolean>(false);
  const [activityOption,setActivityOption]=useState<any>( {
    label:"Active",
    value:1
  },);
  const [show, setShow] = useState<string>("studentList");
  const [editRecord, setEditRecord] = useState<any>(undefined);
  const [activity, setActivity] = useState<any>(1);
  // Drawer states and functions
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [downloadLoading,setDownloadLoading] = useState<string>("")
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [listParams, setListParams] = useState({
    nameSearch: "",
    batchSearch: "",
    streamSearch: "",
    sortCol: "Name",
    sortDir: "ascend",
  });

  const Activity=[
    {
      label:"All",
      value:0
    },
    {
      label:"Active",
      value:1
    },
    {
      label:"In Active",
      value:2
    }
  ]

  const changeListParams = (key: string, value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const downloadDocument = async (val:number,id:string)=>{
    if(val && id ){
      setDownloadLoading(id)
       if(val == 2){
        await downloadCV(id)
       }
       else if(val === 3){
        await  downloadEnrollForm(id)
       }else{
        await  downloadCV(id)
        await downloadEnrollForm(id)

       }
       setDownloadLoading("");
    }
   } 
   const downloadEnrollForm =async (id:string)=>{
    await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {
        enqId:id ,
        type: ViewType.Enroll,
      }
    )
   }
   const downloadCV = async(id:string)=>{
    await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.Download_View_Pdf,
      {enqId: id,
      type: ViewType.CV,}
    );
  }
  const tableLoading = {
    spinning: isTable,
    indicator: <Spin indicator={<LoadingOutlined />} />,
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const [masterData, setMasterData] = useState<{
    batch: any;
  }>({
    batch: [],
  });
  const [streamData, setStremData] = React.useState<any[]>([]);

  const [selectedStatus, setSelectedStatus] = React.useState<any>( {
    value: "active",
    label: "Active",
  });

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        batch: any;
      } = {
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
      };
      setMasterData({ ...obj });

      setStremData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items
      );
    } else {
      message.error(res?.message);
    }
  };
  const downloadCSV = async () => {
    setIsCSVDownloading(true);
    let res = await ApiUtility.downloadFile(
      HTTPSCalls.ENDPOINTS.ENQUIRY_CSV_DOWNLOAD,
      {
        requestType: CandidateRequestType.Student,
        filterId: "",
        batchSearch: listParams?.batchSearch,
        streamSearch: listParams?.streamSearch,
        nameSearch: listParams?.nameSearch,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        userRoleId: "",
      }
    );
    if (res?.status) {
      setIsCSVDownloading(false);
    } else {
      setIsCSVDownloading(false);
    }
  };
  const batchState = useSelector(selectBatchState)
  const getEnquiryData = async () => {
    setIsTable(true);
    await api
      .GET(EnquiryEndPoint.getList + "?", {
        type: CandidateRequestType.Student,
        batchSearch: batchState,
        streamSearch: listParams?.streamSearch,
        nameSearch: listParams?.nameSearch,
        start: (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
        length: pagingItems?.pageSize,
        sortCol: listParams.sortCol,
        sortDir: listParams?.sortDir,
        exists:activity||0
      })
      .then((res: any) => {
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords;
          return p;
        });
        setDataSource(
          res?.result?.items?.map((arrayItem: any, index: number) => ({
            ...arrayItem,
            id: arrayItem?.id,
            sno: arrayItem?.sno,
            key: index,
            name: arrayItem?.name,
            studentId: arrayItem?.studentId,
            fatherName: arrayItem?.fatherName,
            batch: arrayItem?.batch === null ? "-" : arrayItem?.batch?.name,
            email: arrayItem?.contactDetail?.email,
            dob: dayjs(arrayItem?.dateOfBirth).format("DD MMM YYYY"),
            category: arrayItem?.category?.name,
            passoutYear: arrayItem?.currentPassOutYear,
            approvalStatus: arrayItem?.approvalStatus,
            curriculum: arrayItem?.curriculum?.name,
            stream: arrayItem?.stream?.name,
            referralSource: arrayItem?.referralSource,
            mobileNumber: arrayItem?.contactDetail?.mobileNumber,
            amount: arrayItem?.amount,
          }))
        );
        setIsTable(false);
      });
  };
  useEffect(() => {
    getEnquiryData();
  }, [shouldRefresh, listParams, pagingItems,activity,batchState]);

  useEffect(() => {
    getMaster();
  }, []);

  let ApprovalObj: any = {
    0: "Rejected",
    1: "Optional Out",
    2: "On Hold",
    3: "Enrolled",
    4: "All",
  };
  let StudyModeObj: any = {
    0: "Regular",
    1: "Private",
  };
  let LevelObj: any = {
    0: "Beginner",
    1: "Intermediate",
    2: "Advanced",
  };
  // const getStudentRecord = async (id: string) => {
  //   setIsLoading(true);
  //   await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.ENQUIRY_GETALL + "?", {
  //
  //   }).then((res: any) => {
  //     setBasicDetailFrm({
  //       ...res?.result[0],
  //       gender: {
  //         label: res?.result[0]?.gender?.name,
  //         value: res?.result[0]?.gender?.id,
  //       },
  //       category: {
  //         label: res?.result[0]?.category?.name,
  //         value: res?.result[0]?.category?.id,
  //       },
  //       curriculum: {
  //         label: res?.result[0]?.curriculum?.name,
  //         value: res?.result[0]?.curriculum?.id,
  //       },
  //       batch: {
  //         label: res?.result[0]?.batch?.name,
  //         value: res?.result[0]?.batch?.id,
  //       },
  //       stream: {
  //         label: res?.result[0]?.stream?.name,
  //         value: res?.result[0]?.stream?.id,
  //       },
  //       englishProficiency: {
  //         label: LevelObj[res?.result[0]?.englishProficiency],
  //         value: res?.result[0]?.englishProficiency,
  //       },

  //       dateOfBirth:
  //         res?.result[0]?.dateOfBirth === ""
  //           ? undefined
  //           : dayjs(res?.result[0]?.dateOfBirth),
  //       academicRecord: res.result[0]?.academicRecord?.map(
  //         (arrayItem: any, index: any) => ({
  //           key: index,
  //           ...arrayItem,
  //           class: {
  //             label: arrayItem?.class?.name,
  //             value: arrayItem?.class?.id,
  //           },
  //           studyMode: {
  //             label: StudyModeObj[arrayItem?.studyMode],
  //             value: arrayItem?.studyMode,
  //           },
  //         })
  //       ),
  //       attachments: res?.result[0]?.attachments?.map(
  //         (itm: any, index: any) => {
  //           return {
  //             key: index,
  //             ...itm,
  //             fileType: {
  //               label: itm?.fileType?.name,
  //               value: itm?.fileType?.id,
  //             },
  //             fileName: {
  //               id: itm?.fileName?.id,
  //               name: itm?.fileName?.name,
  //               contentType: itm?.fileName?.contentType,
  //               length: itm?.length,
  //               path: itm?.fileName?.path,
  //             },
  //             description: itm?.description,
  //           };
  //         }
  //       ),
  //     });
  //     setCredentialFrm({
  //       approvalStatus: {
  //         label:res?.result[0]?.approvalStatus?.name,
  //         value: res?.result[0]?.approvalStatus?.id,
  //       },
  //       amount: res?.result[0]?.amount,
  //       enrolledDate: res?.result[0]?.enrolledDate,
  //       credentials: res?.result[0]?.credentials?.map(
  //         (arrayItem: any, index: any) => ({
  //           ...arrayItem,
  //           key: index,
  //           credentialName: {
  //             value: arrayItem?.credentialName?.id,
  //             label: arrayItem?.credentialName?.name,
  //           },
  //         })
  //       ),
  //     });
  //     onOpen();
  //     setIsLoading(false);
  //   });
  // };

  // Table related states and objects

  const getById = async (id: string) => {
    setIsLoading(true);
    let res = await api.GET(EnquiryEndPoint.getById + "?", { id });
    setBasicDetailFrm({
      ...res?.result,
      gender: {
        label: res?.result?.gender?.name,
        value: res?.result?.gender?.id,
      },
      medium: {
        label: res?.result?.medium?.name,
        value: res?.result?.medium?.id,
      },
      category: {
        label: res?.result?.category?.name,
        value: res?.result?.category?.id,
      },
      curriculum: {
        label: res?.result?.curriculum?.name,
        value: res?.result?.curriculum?.id,
      },
      batch: {
        label: res?.result?.batch?.name,
        value: res?.result?.batch?.id,
      },
      stream: {
        label: res?.result?.stream?.name,
        value: res?.result?.stream?.id,
      },
      englishProficiency: {
        label: LevelObj[res?.result?.englishProficiency],
        value: res?.result?.englishProficiency,
      },

      dateOfBirth:
        res?.result?.dateOfBirth === ""
          ? undefined
          : dayjs(res?.result?.dateOfBirth),
      academicRecord: res.result?.academicRecord?.map(
        (arrayItem: any, index: any) => ({
          key: index,
          ...arrayItem,
          class: {
            label: arrayItem?.class?.name,
            value: arrayItem?.class?.id,
          },
          studyMode: {
            label: StudyModeObj[arrayItem?.studyMode],
            value: arrayItem?.studyMode,
          },
        })
      ),
      attachments: res?.result?.attachments?.map((itm: any, index: any) => {
        return {
          key: index,
          ...itm,
          fileType: {
            label: itm?.fileType?.name,
            value: itm?.fileType?.id,
          },
          fileName: {
            id: itm?.fileName?.id,
            name: itm?.fileName?.name,
            contentType: itm?.fileName?.contentType,
            length: itm?.length,
            path: itm?.fileName?.path,
          },
          description: itm?.description,
        };
      }),
    });
    setCredentialFrm({
      approvalStatus: {
        label: res?.result?.approvalStatus?.name,
        value: res?.result?.approvalStatus?.id,
      },
      amount: res?.result?.amount,
      cardNo: res?.result?.cardNo,
      studentId: res?.result?.studentId,
      biometricCode: res?.result?.biometricCode,
      enrolledDate:
        res?.result?.enrolledDate === "0001-01-01T00:00:00" ||
        res?.result?.enrolledDate === "0001-01-01T00:00:00Z"
          ? undefined
          : dayjs(res?.result?.enrolledDate),
      credentials: res?.result?.credentials?.map(
        (arrayItem: any, index: any) => ({
          ...arrayItem,
          key: index,
          credentialName: {
            value: arrayItem?.credentialName?.id,
            label: arrayItem?.credentialName?.name,
          },
        })
      ),
    });
    onOpen();
    setIsLoading(false);
  };
  const [studentAssignCardId, setStudentAssignCardId] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [studentIdName,setStudentIdName]=useState<IdName|undefined>();
  const [modalOpen,setModalOpen]=useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    let res: any = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.ASSIGN_CARD + "?",
      { studentId: studentAssignCardId },
      {}
    );
    if (res?.message) {
      message.error(res?.message);
    } else {
      setStudentAssignCardId("");
      message.success("Card assigned successfully");
    }
    setConfirmLoading(false);
    setShouldRefresh((x) => !x);
  };

  const handleCancel = () => {
    setStudentAssignCardId("");
  };

  const columns: ColumnsType<any> = [
    {
      title: "S. No",
      dataIndex: "sno",
      key: "sno",
      width: "4%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Name",
      width: "12%",
      dataIndex: "name",
      key:"Name",
      sorter:true,
      ellipsis: true,

      render: (_, record) => (
        <span
          onClick={() => {
            // getById(record?.id);
            // setEditId(record?.id);
            setEditRecord(record);
            setShow("studentDetails");
          }}
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {record?.fullName}
        </span>
      ),
    },
    {
      title: "Enrollment No",
      width: "10%",
      dataIndex: "enrollmentNumber",
      key:"EnrollmentNumber",
      ellipsis: true,
      sorter:true,
      align: "left",
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Father Name",
      width: "11%",
      ellipsis: true,
      key:"FatherName",
      sorter:true,
      dataIndex: "fatherName",
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    // {
    //   title: "Email",lo
    //   width: "13%",
    //   dataIndex: "email",
    //   render: (text) => <span>{text}</span>,
    // },

    // {
    //   title: "Category",
    //   dataIndex: "category",
    //   width: "6%",
    //   render: (text) => <span>{text}</span>,
    // },

    // {
    //   title: "Curriculum",
    //   dataIndex: "curriculum",
    //   width: "7%",
    //   render: (text) => <span>{text}</span>,
    // },
    {
      title: "Stream",
      width: "10%",
      dataIndex: "stream",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Batch",
      width: "10%",
      dataIndex: "batch",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Mobile No",
      width: "10%",
      dataIndex: "mobileNumber",
      key:"MobileNumber",
      sorter:true,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Student ID",
      width: "8%",
      dataIndex: "studentId",
      key:"StudentId",
      ellipsis: true,
      sorter:true,
      align: "left",
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "D.O.B.",
      width: "8%",
      dataIndex: "dob",
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Amount",
    //   dataIndex: "amount",
    //   width: "10%",
    //   render: (text) => <span>{text}</span>,
    // },
    // {
    //   title: " ",
    //   width: "3%",
    //   dataIndex: "edit",
    //   render: (_, record) => (
    //     <FormOutlined
    //       className="ca-edit-btn"
    //       style={{ color: "#0d6efd" }}
    //       onClick={() => {
    //         getById(record?.id);
    //         setEditId(record?.id);
    //       }}
    //       // color="red"
    //     />
    //   ),
    // },
    {
      title: "Status",
      width: "8%",
      dataIndex: "approvalStatus",
      render: (_: any, record: any) => (
        <>{ProcessStepsEnumName[record?.processStep?.processStep]}</>
      ),
    },
    {
      title: "Card",
      width: "6%",
      dataIndex: "card",
      render: (_: any, record: any) => <>
      
      <Button type="link" size="small"
      disabled={record?.ecard}
      onClick={()=>{
        setStudentIdName({id: record.id, name:record.name});
        setModalOpen(true);
      }}> {
        record?.ecard?"Assigned":"Assign"
      } </Button>
      </>,
    },
    {
      title:"Action",
      dataIndex:"actions",
      key:"actions",
      width:"10%",
      ellipsis:true,
      render:(val:string,record)=>(
        <Select
          size="small"
          loading={downloadLoading===record?.id}
          style={{width:"100%"}}
          placeholder="Download"
          allowClear
          options={[
            { label: "All", value: 1 },
            { label: "CV", value: 2 },
            { label: "Enrollment", value: 3},
          ]}
          onChange={(val)=>{
          downloadDocument(val,record?.id);
          }}
        />
      )
    },
    {
      title: "",
      dataIndex: "name",
      width:"4%",
      render: (text,record) => (
        <>
        <Row justify="end" style={{cursor:"pointer"}}>
          {(record?.recordStatus=== RecordStatusType?.Deleted)  ? (
            <>
            <Popconfirm
            placement="leftTop"
            title={"Are you want student Active"}
            okText="Yes"
            cancelText="No"
            onConfirm={()=>handleActive(record)}
          >
            {/* <UndoOutlined
              className="ca-edit-btn me-2"
              style={{ color: "#a5abdd", cursor: "pointer" }}
            /> */}
            <Tag icon={<CloseCircleOutlined />} color="error" title="INACTIVE"></Tag>
          </Popconfirm>
            
            </>
          ) : (
            <Popconfirm
            placement="leftTop"
            title={"Are you want student InActive"}
            okText="Yes"
            cancelText="No"
            onConfirm={()=>handleInActive(record)}
          >
           {/* <DeleteOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd", cursor: "pointer" }}
            /> */}
            <Tag icon={<CheckCircleOutlined />} color="success" title="ACTIVE"></Tag>
          </Popconfirm>
            
            )}
          </Row>
          {console.log("record",record)}
        </>
      ),
    },
  ];
  const handleInActive = async (record: any) => {
    await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.ENQUIRY_EXISTS+"?",{
      id:record?.id,
      activity:false,
    })
    getEnquiryData();
    console.log("inactive",record);
  };

  const handleActive = async (record: any) => {
    await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.ENQUIRY_EXISTS+"?",{
      id:record?.id,
      activity:true,
    })
    getEnquiryData();
    console.log("inactive",record);
  };

  const handleUndo = (record: any) => {
    message.success("Undo successful.");
  };
  //---------------------------------------------- Get and post function for master and enquiry -----------------------------------------------------

  return (
    <>
      {/* <EnquiryForm
            userType={userType}
            isOpen={isOpen}
            openDrawer={onOpen}
            type={CandidateRequestType.Student}
            closeDrawer={onClose}
            editId={editId}
            setEditId={setEditId}
            setIsLoading={setIsLoading}
            studentIsEdited={true}
            onDismiss={(rec: any) => {
              setIsOpen(false);
              if (rec) setShouldRefresh((x) => !x);
            }}
            setShouldRefresh={setShouldRefresh}
            basicDetail={basicDetailFrm}
            credentialDetail={credentialFrm}
          /> */}
  <CardModel student={studentIdName} modalOpen={modalOpen} setModalOpen={setModalOpen} setShouldRefresh={setShouldRefresh}/>
      <div>
        {show === "studentDetails" && (
          <New_Student_Form
            userType={userType}
            editRecord={editRecord}
            type={CandidateRequestType.Student}
            onDismiss={(rec: boolean) => {
              setIsOpen(false);
              setBasicDetailFrm(null);
              setCredentialFrm(null);
              setShow("studentList");
              setEditId("");
              onClose();
              if (rec) setShouldRefresh((x) => !x);
            }}
            setShouldRefresh={setShouldRefresh}
          />
        )}

        {show === "studentList" && (
          <div className="roundedCornerMedium bg-white px-3 py-2" style={{minHeight:825}}>
            <Row justify="end" align="middle" style={{ marginBottom: "6px" }}>
              {/* <Breadcrumb
                style={{ color: "#172B4D" }}
                items={[
                  {
                    title: "Students",
                  },
                ]}
              ></Breadcrumb> */}
            </Row>

            <Row
              
              // style={{ padding: "16px" }}
            >
              <Col span={24}>
                <Row justify="space-between" >
                  <Col span={12}>
                    <Space.Compact block>
                      <Button
                        type="primary"
                        ghost
                        className="cit-add-btn"
                        onClick={() => {
                          downloadCSV();
                        }}
                        icon={<DownloadOutlined />}
                        loading={isCSVDownloading}
                      >
                        CSV
                      </Button>
                    </Space.Compact>
                  </Col>
                  <Row >
                  <Col>
                    <Select
                      // loading={isLoading}
                      popupClassName="cit-select-box"
                      className="me-2"
                      
                      value={activityOption}
                      defaultActiveFirstOption
                      style={{ width: "160px" }}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      // onClear={() => {
                      //   setActivityOption(null);
                      //   setActivity(0);
                      //   // changeListParams("streamSearch", "");
                      // }}
                      options={Activity}
                      onSelect={(val: any,record) => {
                        setActivity(val)
                        setActivityOption(record)
                        // console.log("val",val);
                        
                      }}
                    ></Select>
                  </Col>
                  <Col>
                    <Select
                      // loading={isLoading}
                      popupClassName="cit-select-box"
                      className="me-2"
                      labelInValue
                      placeholder={"Select Stream"}
                      allowClear
                      showSearch
                      defaultActiveFirstOption
                      style={{ width: "160px" }}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onClear={() => {
                        changeListParams("streamSearch", "");
                      }}
                      options={streamData}
                      onSelect={(val: any) => {
                        changeListParams("streamSearch", val?.value);
                      }}
                    ></Select>
                  </Col>
                  {/* <Col>
                    <Select
                      // loading={isLoading}
                      popupClassName="cit-select-box"
                      className="me-2"
                      labelInValue
                      placeholder={"Select Batch"}
                      allowClear
                      showSearch
                      defaultActiveFirstOption
                      style={{ width: "160px" }}
                      onClear={() => {
                        changeListParams("batchSearch", "");
                      }}
                      options={masterData?.batch}
                      onSelect={(val: any) => {
                        changeListParams("batchSearch", val?.value);
                      }}
                    ></Select>
                  </Col> */}
                  <Col>
                    <Search
                      size="middle"
                      placeholder="Search Name..."
                      allowClear
                      className="att-search-input mb-1"
                      onSearch={(val: string) =>
                        changeListParams("nameSearch", val)
                      }
                      // onChange={(e: any) =>
                      //   e.target.value === "" ? setListParamsAndRefresh("") : null
                      // }
                      style={{ width: "160px" }}
                    />
                  </Col>
                  </Row>
                </Row>
                <Row>
                <Col span={24} style={{ paddingTop: "12px" }}>
                  <Table
                   
                    size="small"
                    style={{ 
                       width:"100%",
                      maxHeight:730,
                      overflow:"auto",
                      minHeight:700}}
                    
                    className="cit-table fs-13 "
                    dataSource={dataSource}
                    loading={tableLoading}
                    columns={columns}
                    scroll={{ x: 1100, y: "65vh" }}
                    pagination={{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                      selectPrefixCls: "custom-table-select",
                      current: pagingItems.currentPage,
                      pageSize: pagingItems.pageSize,
                      showSizeChanger: true,
                      total: pagingItems.totalRecords,
                      pageSizeOptions: ["15", "25", "50", "100"],
                    }}
                    onChange={(paging, filter, sort: any) => {
                      sort?.order &&
                        setListParams({
                          ...listParams,
                          sortDir: sort?.order,
                          sortCol: sort?.columnKey,
                        });
                      paging &&
                        setPagingItems({
                          ...pagingItems,
                          currentPage: paging?.current ?? 1,
                          pageSize: paging?.pageSize ?? 15,
                        });
                    }}
                    //
                  />
                </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default StudentsList;
