import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import {
  leaveStatusEnum,
  leaveStatusObject,
  LeaveTypeEnum,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";

const LeaveReqModal = (props: any) => {
  const { open, handleCancel, tableRecord, setRefresh } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    console.log(tableRecord, "tablered");
  }, [tableRecord]);
  useEffect(() => {
    console.log(tableRecord, "tablered");
  }, [tableRecord]);

  useEffect(() => {
    if (tableRecord) {
      form.setFieldsValue({
        status: tableRecord?.status,
        remark: tableRecord?.remark || "",
      });
    }
  }, [tableRecord, form]);
  const handleSubmit = async () => {
    try {
      // Get form values
      const formValues = form.getFieldsValue();

      const payload = {
        id: tableRecord?.id,
        status: formValues.status,
        remark: formValues.remark,
      };
      // Send the POST request
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.LEAVE_ACTION,
        {},
        payload
      );
      if (res?.result) {
        setRefresh((prev: boolean) => !prev);
        message.success("Leave request submitted successfully.");
        console.log(formValues, "res=-");
      }
      form.resetFields();
      handleCancel();
    } catch (error: any) {
      message.error(
        error.message || "An error occurred while submitting the leave request."
      );
    }
  };

  const leaveStatusArray = Object.keys(leaveStatusObject).map((key) => ({
    value: leaveStatusObject[key as keyof typeof leaveStatusObject],
    label: key,
  }));

  return (
    <>
      <Modal
      okText={"Save"}
        title="Leave Request"
        open={open}
        onCancel={handleCancel}
        onOk={() => handleSubmit()}
      // confirmLoading={confirmLoading}
      // onCancel={handleCancel}
      >
        <div
          style={{
            height: "100%",
            border: "1.5px solid lightgray",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <Form requiredMark={false} form={form} style={{ lineHeight: 2.5 }}>
            <Row>
              <Col span={6}>
                <p>Name</p>
              </Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {tableRecord?.createdBy?.userName}
                </p>
              </Col>
              <Col span={6}>Type</Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  
                  {tableRecord?.leaveType?.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>Applied date</p>
              </Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {dayjs(tableRecord?.createdBy?.date).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col span={6}>Days</Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {(() => {
                    const fromDate = dayjs(tableRecord?.date?.from);
                    const toDate = dayjs(tableRecord?.date?.to);
                    let daysDifference = toDate.diff(fromDate, "day");

                    if (daysDifference === 0) {
                      daysDifference = 1;
                    }
                    return `${daysDifference} days`;
                  })()}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>From</p>
              </Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {dayjs(tableRecord?.date?.from).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col span={6}>To</Col>
              <Col span={6}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {dayjs(tableRecord?.date?.to).format("DD/MM/YYYY")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>Reason</p>
              </Col>
              <Col span={18}>
                <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                  {tableRecord?.reason}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={19}></Col>
              <Col span={5}>
                {/* <Button size="small">
                  <PlusOutlined style={{ color: "gray" }} /> Review
                </Button> */}
              </Col>
              <Divider
                orientation="left"
                style={{ marginTop: "2px", fontSize: "14px" }}
              >
                Final Approvals
              </Divider>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  label="Status"
                  rules={[
                    { required: true, message: "Please select a status!" },
                  ]}
                  name={"status"}
                >
                  
                  <Select
                    placeholder="Select Leave Status"
                    style={{ width: 200 }}
                    options={leaveStatusArray}
                    disabled={props?.tableRecord?.status !== leaveStatusEnum.Pending}
                  />
                  {/* {props?.tableRecord?.status === leaveStatusEnum.Pending ? <Select
                    placeholder="Select Leave Status"
                    style={{ width: 200 }}
                    options={leaveStatusArray}
                  />:leaveStatusEnum[props?.tableRecord?.status]
                } */}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Remark" rules={[
                  { required: true, message: "Please add remark!" },
                ]} name={"remark"}>
                  <Input placeholder="Add Remarks" disabled={props?.tableRecord?.status !== leaveStatusEnum.Pending} />
                </Form.Item>
              </Col>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Note: This remark will be visible to requesting employee and the
                management
              </p>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LeaveReqModal;
